import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Typography,
  Box,
  Container,
  Section,
  Layout,
  Grid,
} from '../components';
import styled from 'styled-components';
import ServiceHeroSection from '../components/ServiceHeroSection';
import bannerImage from '../images/services/mlSolution/mLSolutionBanner.svg';
import dataAnalystImg from '../images/services/mlSolution/dataAnalysis.svg';
import generativeAi from '../images/services/mlSolution/generativeAI.svg';
import machineLearning from '../images/services/mlSolution/machineLearning.svg';

const ServiceContentImage = styled.img`
  box-shadow: 25px 25px 0px 0px var(--secondary-color);
  margin: 25px 0;
  width: 100%;

  @media screen and (max-width: 768px) {
    box-shadow: 10px 10px 0px 0px var(--secondary-color);
  }
`;

const aiMlSolution = () => {
  return (
    <Layout title="AI/ML Solution">
      <Helmet>
        <meta
          name="description"
          content="Spring Rain's AI & ML services drive automation, customer engagement, and advanced analytics for sustainable growth. Data analysis, generative AI, and machine learning solutions tailored to your needs."
        />
      </Helmet>
      <Container mt={{ xs: 8, md: 12 }}>
        <ServiceHeroSection
          title={'AI/ML Solution'}
          imageSrc={bannerImage}
          imgAlt={'AI/ML Solution'}
        />
        <Section>
          <Typography my={5} variant="paragraph1">
            Spring Rain's AI & ML services and solutions empower organizations
            to process automation, strengthen customer engagement, and leverage
            advanced analytics for long-term sustainability and growth.
          </Typography>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box>
              <ServiceContentImage src={dataAnalystImg} />
            </Box>
            <Box>
              <Typography mt={2} variant="heading5">
                Data Analysis
              </Typography>

              <Typography mt={2} variant="paragraph1">
                We help identify trends, predict customer behavior, and optimize
                marketing campaigns by harnessing AI's advanced capability so
                you can find new opportunities to grow.
              </Typography>
            </Box>
          </Grid>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box flip={true}>
              <Typography mt={2} variant="heading5">
                Generative AI
              </Typography>

              <Typography mt={2} variant="paragraph1">
                We enhance your creative processes, accelerate data synthesis,
                and get personalized content generation, such as images, text,
                or videos, tailored to your target audience.
              </Typography>
            </Box>
            <Box>
              <ServiceContentImage src={generativeAi} />
            </Box>
          </Grid>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box>
              <ServiceContentImage src={machineLearning} />
            </Box>
            <Box>
              <Typography mt={2} variant="heading5">
                Machine Learning and Deep Learning
              </Typography>

              <Typography mt={2} variant="paragraph1">
                We build and train models that can learn from data, identify
                patterns, and make decisions with minimal human intervention.
              </Typography>
            </Box>
          </Grid>
        </Section>
      </Container>
    </Layout>
  );
};

export default aiMlSolution;
